import React, { useState, useEffect } from 'react'
import { styles } from './styles'
import { ROUTES } from './../../constants'
import CHRContentContainer from './../contentContainer'
import chirpyestLogo from './../../assets/images/chirpyest-logo.svg'
import CHRFooterLinks from '../footerLinks'
import CHRSocialMedia from '../socialMedia'
import CHRNewsLetter from '../newsLetterForm'
import { withTranslation } from 'react-i18next'
import { TFunction } from 'i18next'
import { Link } from 'gatsby'
import { isUserAuthenticated } from './../../../utils/isUserAuthenticated'
import 'lazysizes'

interface FooterProps {
  t: TFunction
}

const CHRFooter = ({ t }: FooterProps) => {
  const classes = styles()
  const [isLoggedIn, setIsLoggedIn] = useState(false)

  useEffect(() => {
    isUserAuthenticated().then(res => {
      setIsLoggedIn(res)
    })
  }, [])

  return (
    <footer className={classes.footer}>
      <CHRContentContainer>
        <div className={classes.footerInnerWrapper}>
          <CHRFooterLinks />
          <div className={`${classes.child} ${classes.newsletterChild}`}>
            <CHRNewsLetter />
            <div className={classes.followOnSM}>
              <h6 className={classes.followOnSMTitle}>
                {t('footer.followUs')}:
              </h6>
              <CHRSocialMedia />
            </div>
          </div>
          <div className={classes.logoChild}>
            <Link
              rel="preconnect"
              to={isLoggedIn ? ROUTES.member : ROUTES.home}
            >
              <img
                src={chirpyestLogo}
                width="80"
                height="85"
                alt={t('shared.chirpyest')}
                className={classes.chirpyestLogo + ' lazyload '}
              />
            </Link>
          </div>
        </div>
        <div className={classes.rightsReserved}>
          <p>© Chirpyest Inc. {new Date().getFullYear()} all rights reserved</p>
        </div>
      </CHRContentContainer>
    </footer>
  )
}

export default withTranslation()(CHRFooter)
