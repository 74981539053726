import * as React from 'react'
import { styles } from './styles'
import { SOCIAL_MEDIA_LINKS } from '../../constants'
import instagramIcon from './../../assets/images/instagram.svg'
import pinterestIcon from './../../assets/images/pinterest.svg'
import facebookIcon from './../../assets/images/facebook.svg'
import twitterIcon from './../../assets/images/twitter.svg'
import tiktokIcon from './../../assets/images/tiktok.svg'
import 'lazysizes'

interface SocialMediaProps {}

const CHRSocialMedia = (props: SocialMediaProps) => {
  const classes = styles()
  return (
    <ul className={classes.socialMediaList}>
      <li className={classes.socialListItem}>
        <a
          href={SOCIAL_MEDIA_LINKS.instargram}
          target="_blank"
          className={classes.socialIcon}
          rel="noreferrer"
        >
          <img src={instagramIcon} alt="instagram" width="25" height="25" />
        </a>
      </li>
      <li className={classes.socialListItem}>
        <a
          href={SOCIAL_MEDIA_LINKS.tiktok}
          target="_blank"
          className={classes.socialIcon}
          rel="noreferrer"
        >
          <img
            src={tiktokIcon}
            className="lazyload"
            alt="tiktok"
            width="25"
            height="25"
          />
        </a>
      </li>
      <li className={classes.socialListItem}>
        <a
          href={SOCIAL_MEDIA_LINKS.pinterest}
          target="_blank"
          className={classes.socialIcon}
          rel="noreferrer"
        >
          <img src={pinterestIcon} alt="pinterest" width="25" height="25" />
        </a>
      </li>
      <li className={classes.socialListItem}>
        <a
          href={SOCIAL_MEDIA_LINKS.facebook}
          target="_blank"
          className={classes.socialIcon}
          rel="noreferrer"
        >
          <img src={facebookIcon} alt="facebook" width="25" height="25" />
        </a>
      </li>
      <li className={classes.socialListItem}>
        <a
          href={SOCIAL_MEDIA_LINKS.twitter}
          target="_blank"
          className={classes.socialIcon}
          rel="noreferrer"
        >
          <img src={twitterIcon} alt="twitter" width="25" height="25" />
        </a>
      </li>
    </ul>
  )
}

export default CHRSocialMedia
