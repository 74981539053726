import Auth, { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth'
import { Link, navigate } from 'gatsby'
import { TFunction } from 'i18next'
import React, { useState, useEffect, useRef } from 'react'
import { withTranslation } from 'react-i18next'
import { signInValidation } from '../../../utils/validation'
import { useAppState } from '../../appState'
import emailIcon from '../../assets/images/email.svg'
import googleIcon from '../../assets/images/google_logo.svg'
import appleIcon from '../../assets/images/apple_logo.svg'
// import facebookIcon from '../../assets/images/facebook_logo.svg'
import axios from '../../axios'
import { ROUTES, EXTENSION_ID, ENDPOINTS } from '../../constants'
import { styles } from '../../pagesStyles/joinsStyles'
import CHRButton from '../button'
import CHRInput from '../input'
import CHRText from '../typography'
import { checkIfSubmittable } from './../../../utils/validation'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye } from '@fortawesome/free-solid-svg-icons'
import { faEyeSlash } from '@fortawesome/free-solid-svg-icons'
import { sendExtensionMessage } from '../../../utils/extensionHelper'
import { TrackingCase } from '../../../utils/trackingCases'
import { tracker } from '../../systemLogs'
import ReCAPTCHA from 'react-google-recaptcha'
declare const window: any

const eye = <FontAwesomeIcon icon={faEye} />
const eyeSlash = <FontAwesomeIcon icon={faEyeSlash} />

interface SignInProps {
  t: TFunction
  fromGatePage?: boolean
}

const SignIn = ({ t, fromGatePage }: SignInProps) => {
  const classes = styles()

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [formErrors, setFormErrors] = useState({
    emailMessage: '',
    passwordMessage: '',
  })
  const [requestError, setRequestError] = useState(null)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [passwordShown, setPasswordShown] = useState(false)
  const [, dispatch] = useAppState()

  const reRef = useRef<ReCAPTCHA>(null)
  const [captchaVerified, setCaptchaVerified] = useState(false)
  const [tryCaptcha, setTryCaptcha] = useState(0)

  const onChange = async (token: string) => {
    if (token === null) {
      reRef.current.reset()
      setCaptchaVerified(false)
    } else {
      setTryCaptcha((preState: number) => (preState += 1))
      setRequestError('')
      const response = await axios.post(ENDPOINTS.verifyCaptcha, { token })
      if (response?.data?.data?.success) {
        setCaptchaVerified(true)
      } else {
        reRef.current.reset()
        setRequestError('Captcha token not verified from server!')
      }
    }
  }

  const togglePasswordVisiblity = () => {
    setPasswordShown(!passwordShown)
  }

  const handleSignIn = async (username: string, password: string) => {
    setIsSubmitting(true)
    setFormErrors({ emailMessage: '', passwordMessage: '' })
    try {
      if (captchaVerified === false && tryCaptcha == 0) {
        setRequestError('Please verify captcha!')
      } else if (captchaVerified === false && tryCaptcha > 0) {
        setRequestError('Please verify captcha again!')
      } else if (captchaVerified) {
        const isFormValid: any = await signInValidation({ email, password })
        const user = await Auth.signIn(username, password)
        if (user.signInUserSession.accessToken) {
          // should be changed depends on the env
          const {
            data: { data },
          } = await axios.get(
            ENDPOINTS.user.replace(':id', user.attributes['custom:user_id'])
          )
          const currentUserInfo = await Auth.currentCredentials()
          const session = await Auth.currentSession()
          const userProfile = {
            email: data.email,
            userName: data.username,
            fullName: data.name,
            imageKey: data.image,
            imageUrl: '' as Object | string,
            isPro: data.isPro,
            isCreator: data.isCreator,
            isReferralBonus: data.isReferralBonus,
            userDoublePayment: data.userDoublePayment,
            financialData: data.financialData || {
              pending: '0',
              lastClosedOut: '0',
              receivableMilestone: '0',
            },
            loginProvider: data.loginProvider,
          }

          if (data.image && currentUserInfo?.identityId) {
            const url = `https://${process.env.GATSBY_S3_BUCKET_NAME}.s3.us-east-2.amazonaws.com/public/${data.image}`
            userProfile.imageUrl = url
          }
          sendExtensionMessage({ session })
          if (window.chrome?.runtime?.sendMessage) {
            window.chrome.runtime.sendMessage(
              EXTENSION_ID,
              { session },
              _response => {}
            )
          }
          dispatch({ type: 'SIGN_IN', auth: user, userProfile })
          const hashFragment = window.location.href.split('#')[1]?.split('?')[1]
          const hashParams = new URLSearchParams(hashFragment)
          const redirectParam = hashParams.get('redirect')
          if (redirectParam) {
            navigate(redirectParam)
            return
          }

          const urlParams = new URLSearchParams(window.location.search)
          const signInFromExtension = urlParams.get('signInFromExtension')
          navigate(
            `${ROUTES.member}${
              signInFromExtension
                ? '?signInFromExtension=signInFromExtension'
                : ''
            }`
          )
        } else if (user.signInUserSession.accessToken) {
          setRequestError(t('messages.notAuthorized'))
          Auth.signOut()
          sendExtensionMessage({ action: 'signOut' })
          if (window.chrome?.runtime?.sendMessage) {
            window.chrome.runtime.sendMessage(
              EXTENSION_ID,
              { action: 'signOut' },
              (_response: any) => {}
            )
          }
        }
      }
    } catch (error) {
      if (error.errors) {
        if (error.inner[0]?.params.path === 'email') {
          setFormErrors({
            emailMessage: error.inner[0]?.message,
            passwordMessage: error.inner[1]?.message,
          })
        } else {
          setFormErrors({ passwordMessage: error.inner[0]?.message })
        }
        return
      }
      setRequestError(t('messages.signInError'))
      tracker.track(TrackingCase.UserTracking, `login failed`, {
        errorMessage: error.message,
        email,
        username,
      })
    } finally {
      setIsSubmitting(false)
    }
  }
  return (
    <div className={classes.container}>
      <CHRText align="left">
        {!fromGatePage && (
          <span className={classes.signInText}>
            {t('login.signInToChirpyest')}
          </span>
        )}
      </CHRText>
      <div className={classes.signInWithEmail}>
        {formErrors.emailMessage && (
          <p className={classes.errorMessage}>{formErrors.emailMessage}</p>
        )}
        <CHRInput
          value={email}
          onChange={e => {
            setEmail(e.target.value)
          }}
          placeholder={t('shared.emailAddress')}
          type="email"
          error={formErrors.emailMessage}
          onKeyPress={event =>
            !isSubmitting &&
            checkIfSubmittable(event, handleSignIn, email, password)
          }
        />
      </div>
      <div className={classes.signInWithEmail}>
        {formErrors.passwordMessage && (
          <p className={classes.errorMessage}>{formErrors.passwordMessage}</p>
        )}
        <div className={classes.passwordField}>
          <CHRInput
            value={password}
            onChange={e => {
              setPassword(e.target.value)
            }}
            placeholder={t('shared.password')}
            type={passwordShown ? 'text' : 'password'}
            error={formErrors.passwordMessage}
            onKeyPress={event =>
              !isSubmitting &&
              checkIfSubmittable(event, handleSignIn, email, password)
            }
          />
          <i
            className={classes.passVisibility}
            onClick={togglePasswordVisiblity}
          >
            {passwordShown ? eye : eyeSlash}
          </i>
        </div>
      </div>

      <div style={{ margin: 'auto', paddingTop: '15px' }}>
        <ReCAPTCHA
          sitekey={`${process.env.GATSBY_RECAPTCHA_SECRET_KEY}`}
          onChange={onChange}
          ref={reRef}
        />
      </div>

      <div className={classes.signInWithEmail}>
        {requestError && <p className={classes.errorMessage}>{requestError}</p>}
        <CHRButton
          onClick={() => handleSignIn(email, password)}
          label={
            fromGatePage ? t('gate.charterSignIn') : t('login.signInWithEmail')
          }
          icon={emailIcon}
          isSubmitting={isSubmitting}
          disabled={isSubmitting}
        />
        <CHRText size="small">or</CHRText>
        <CHRButton
          onClick={() =>
            Auth.federatedSignIn({
              provider: CognitoHostedUIIdentityProvider.Google,
            })
              .then(cred => {
                console.log(cred)
                window.Sentry.captureMessage('Google Login Success')
                window.Sentry.captureException(cred)
              })
              .catch(e => {
                console.log(e)
                window.Sentry.captureMessage('Google Login Error')
                window.Sentry.captureException(e)
              })
          }
          label={t('login.signInWithGoogle')}
          icon={googleIcon}
          isSubmitting={isSubmitting}
          disabled={isSubmitting}
        />
        {/* <CHRButton
          onClick={() =>
            Auth.federatedSignIn({
              provider: CognitoHostedUIIdentityProvider.Facebook,
            })
          }
          label={t('login.signInWithFacebook')}
          icon={facebookIcon}
          isSubmitting={isSubmitting}
          disabled={isSubmitting}
        /> */}
        <CHRButton
          onClick={() =>
            Auth.federatedSignIn({
              provider: CognitoHostedUIIdentityProvider.Apple,
            })
          }
          label={t('login.signInWithApple')}
          icon={appleIcon}
          isSubmitting={isSubmitting}
          disabled={isSubmitting}
        />
        <div className={classes.bottomStrip}>
          <div>
            no account?{' '}
            <Link
              rel="preconnect"
              to={ROUTES.join}
              className={classes.forgotPassword}
            >
              {t('login.joinHere')}
            </Link>
          </div>
          <div>
            <Link
              rel="preconnect"
              to={ROUTES.forgotPassword}
              className={classes.forgotPassword}
            >
              {t('login.forgotPassword')}
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default withTranslation()(SignIn)
